import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import BgImage from "components/BgImage"
import Button from "components/Button"
import PropTypes from "prop-types"

const LandingContainer = styled(`section`)`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  height: 500px;

  h1,
  h2 {
    color: #162a3e;
    font-size: 2rem;
    margin: 1rem auto;
    text-align: center;
    padding: 0 15px;
  }

  h2 {
    font-size: 1.8rem;
  }

  @media only screen and (min-width: 1024px) {
    h1 {
      max-width: 900px; /* Adjust this value based on your design needs */
      margin: 0 auto; /* Centers the text if the container is wider */
      font-size: 45px;
    }
  }
`

const ImageCover = styled.div`
  position: absolute;
  top: 0;
  height: 500px;
  width: 100vw;
  z-index: -1;
  background: linear-gradient(
    45deg,
    rgba(255, 200, 0, 0.75),
    rgba(0, 153, 255, 0.75)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc800",endColorstr="#0099ff",GradientType=1);
`

const ButtonsContainer = styled(`div`)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 100%;
`

const CustomBgImage = styled(BgImage)`
  height: 500px !important;
  filter: grayscale(1) brightness(0.9);
  opacity: 0.5;
`

const LandingSection = ({ image }) => (
  <LandingContainer>
    <CustomBgImage fluid={image.fluid} objectPosition={"50% 70%"} />
    <ImageCover />
    <div>
      <h1>
        Dependable software and service fueling faster workflows and more
        savings
      </h1>
    </div>
    <ButtonsContainer>
      {/* <Link to="/services" style={{ padding: 5 }}>
        <Button>Our Services</Button>
      </Link>*/}
      <Link to="#contact" style={{ padding: 5 }}>
        <Button>Contact Us</Button>
      </Link>
    </ButtonsContainer>
  </LandingContainer>
)

LandingSection.propTypes = {
  image: PropTypes.object.isRequired,
}

export default LandingSection
