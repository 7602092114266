import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const ChildStyle = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  justify-content: space-evenly;

  @media (max-width: 860px) {
    grid-template-columns: 1fr; /* All items in a single column for smaller screens */
    max-width: 100%;
  }
`
const ContainerStyle = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-family: "Poppins", sans-serif;;
  justify-content: space-around;
  flex-wrap: nowrap;
  padding: 2rem 1rem;
  margin: 0 auto;

  > * {
    padding: 1rem 0;
    max-width: 1200px;
  }
  }
  ${({ secondary }) =>
    secondary &&
    `
    background: rgb(242, 232, 223);
    p, h2: {color: #00264c};
  `}
  ${({ primary }) =>
    primary &&
    `
    background: #00264c;
    p, h2 {color: rgb(242, 232, 223)};
  `}
  ${({ contact }) => contact && "h2, p {color: rgb(242, 232, 223);}"}
  ${({ noTitle }) => noTitle && "h2 {display: none;}"}

  h2 {
    text-align: center;
    text-decoration: underline;
  }
  h2,
  p,
  form {
    padding: 1rem 0;
    margin: 0 auto;
    max-width: 1200px;
  }

  label {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }

  input,
  textarea {
    padding: 0.5rem;
  }

  textarea {
    height: 8rem;
  }  

  .control-arrow {
    color: #000 !important; /* Replace with your desired color */
  }
  
  /* Change the color of the arrow on hover */
  .control-arrow:hover {
    color: #00ff00; /* Replace with your desired hover color */
  }
  /* CSS for setting carousel width */
.carousel {
  width: 100%; /* Adjust width as needed */
  max-width: 850px; /* Set a maximum width if desired */
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000;
`
const NewsContainer = ({
  title,
  secondary,
  noTitle,
  contact,
  children,
  header,
  ...props
}) => {
  const [isMobileView, setIsMobileView] = useState(false)

  useEffect(() => {
    // Check if window is defined (client-side)
    if (typeof window !== "undefined") {
      setIsMobileView(window.innerWidth < 768) // Adjust the breakpoint as needed
    }
  }, [])
  return (
    <ContainerStyle
      {...props}
      secondary={secondary}
      contact={contact}
      noTitle={noTitle}
    >
      <h2>{title}</h2>
      <p>{header}</p>
      {/* <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true} // Enable autoplay
        interval={3000} // Set the time interval in milliseconds (e.g., 3000ms or 3 seconds)
      > */}
      <ChildStyle>{children}</ChildStyle>
      {/* </Carousel> */}
    </ContainerStyle>
  )
}

NewsContainer.propTypes = {
  header: PropTypes.string,
  primary: PropTypes.bool.isRequired,
  secondary: PropTypes.bool.isRequired,
  contact: PropTypes.bool.isRequired,
  noTitle: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

NewsContainer.defaultProps = {
  header: "",
  primary: false,
  secondary: false,
  contact: false,
  noTitle: false,
  title: "",
}

export default NewsContainer
