import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"

import Layout from "components/layout"
import SEO from "components/seo"
import Container from "components/Container"
import NewsContainer from "components/NewsContainer"
import ServicesContainer from "components/ServicesContainer"
import LandingSection from "components/LandingSection"
import ContactForm from "components/ContactForm"
import Button from "components/Button"
import BulletList from "components/BulletList"
import BlogListing from "components/BlogListing"
import Collab from "images/undraw_collaborators_re_hont.svg"
import ProjectListing from "../components/ProjectListing"

// const Bar = styled(`div`)`
//   background: #162a3e;
//   width: 25px;
//   height: 344px;
//   position: absolute;
//   border-radius: 0 0 300px 0;
//   ${({ right }) => right && "left: calc(100% - 25px);"}
//   ${({ right }) =>
//     right &&
//     "border-radius: 0 0 0 300px;"}

//   @media only screen and (min-width: 413px) {
//     height: 244px;
//   }
// `

export const UBS = ["Has your business outgrown its current software"]

export const BMS = [
  "Do you want to save time and money by automating workflows",
]

const CTN = ["Do you need business software developed in less time"]

const CMD = ["Are you looking to launch software for paying subscribers"]

// export const EXT = [
//   "Explore more if",
//   "Your startup needs a Chrome Extension or Google Workspace Extension",
// ]

// const DEVELOPMENT_LIST = [
//   "A custom website to fit your business needs",
//   "We take you through the entire process from design to implementation",
// ]

// const HOSTING_LIST = [
//   "Web hosting to meet all of your website's traffic requirements",
//   "Gain access to your own hosting server through your cPanel account",
// ]

// const SEO_LIST = [
//   "Rank higher on Google and other search engines",
//   "Stay competitive in your industry",
//   "Take advantage of your business's strengths",
// ]

// const ECOMMERCE_LIST = [
//   "Allow customers to buy your product on your own online store",
//   "Customers can check out and pay right on your website",
// ]

// const CMS_LIST = [
//   "Add, remove, or update content on your own site",
//   "Great for blogs, menus, and eCommerce sites",
// ]

export const LinkContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1100px;
  justify-content: space-evenly;
  margin: 0 25px;
  gap: 0 1rem;

  @media (max-width: 860px) {
    grid-template-columns: 1fr; /* All items in a single column for smaller screens */
    max-width: 100%;
    gap: 0px 40px;
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="StreamAlign Software" />
    <LandingSection image={data.homeBgImage.childImageSharp} />

    <Container contact title="Software For Any Business">
      <p>We understand the challenges businesses face when getting software.</p>
      <p>
        We work hard to build your trust and be like your in home software team
        by crafting software to help you run faster and more efficiently, and
        maintenance and support for your new system. You call and we'll be
        there.
      </p>
    </Container>

    {/* <div style={{ position: "relative", width: "100%" }}> */}
    <Container secondary title="Our Mission">
      {/* <p>
          That are dissatisfied with their current software solution not meeting
          their day to day needs. We work closely with you to provide custom
          software solutions that are tailored just for you.
        </p> */}

      {/* <LinkContainer> */}
      {/* <div> */}
      <ul className="custom-bullets">
        <li>
          Empower you with software that puts you in full control of your data.
        </li>
        <li>
          Reduce development costs by leveraging commercial software and
          crafting custom solutions that capture your unique business processes.
        </li>
        <li>Automate processes to save countless hours of manual work.</li>
        <li>
          Accelerate the flow of information to your employees and customers.
        </li>
        <li>
          Deliver ongoing technical support to keep your operations running
          smoothly.
        </li>
      </ul>
      {/* </div> */}
      {/* <div>
            {" "}
            <img src={Collab} style={{ width: "100%" }} />
          </div> */}
      {/* </LinkContainer> */}
    </Container>

    {/* <Container title="Talk With Us Today">
        <p>
          Call or message us for an initial conversation and afterwards we will
          schedule a free 1 hour meeting with you and the whole team to get more
          information about your project and to get to know each other. If your
          project is beyond our abilities we won't waste your time and won't
          take on your project.
        </p>
      </Container> */}
    {/* </div> */}

    {/* <Container title="Core Technologies" secondary>
      <TechIcons />
    </Container> */}

    <NewsContainer
      primary
      title="Services"
      header="For pricing transparency: estimates are based on 100 USD per hour per developer in blocks of weeks. We are open to negotiation, working for equity, and taking on juniors if they bring us a project."
    >
      {data.allMarkdownRemark.edges
        .filter(e => e?.node?.frontmatter?.path?.match("services"))
        .map((post, index) => (
          <div style={{ margin: `0 1.5rem` }} key={index}>
            <ProjectListing post={post.node} featured height="300px" />
          </div>
        ))}
    </NewsContainer>

    <NewsContainer title="Portfolio" secondary>
      {data.allMarkdownRemark.edges
        .filter(e => e?.node?.frontmatter?.path?.match("portfolio"))
        .map((post, index) => (
          <div style={{ margin: `0 1.5rem` }} key={index}>
            <ProjectListing post={post.node} featured />
          </div>
        ))}
    </NewsContainer>

    {/* <Container title="Newsletter">
      <p>
        
      </p>
    </Container> */}

    <Container id="contact" title="Contact Us" style={{}} contact>
      <ContactForm />
    </Container>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const query = graphql`
  query {
    homeBgImage: file(relativePath: { eq: "home-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 4831) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      limit: 30
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            tags
            cost
            location
            summary
            listingOrder
            link
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
