import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import Modal from "react-modal"

const ProjectContainer = styled.section`
  .container {
    border-top: 1px solid #ddd;
    padding: 20px;
    max-width: 1000px;
    min-height: ${({ height }) =>
      height || "650px"} !important; /* Use prop or default */
    margin: 30px auto;
    background: #fff;
    box-shadow: 10px 10px rgba(0, 153, 255, 1),
      /* Blue shadow */ -10px -10px rgba(255, 200, 0, 1); /* Yellow shadow */
    border-radius: 8px;

    &.flex {
      display: flex;
    }
  }
  .image {
    flex: 1;
    max-width: inherit;
    &.margin-right {
      margin-right: 20px;
    }
    cursor: pointer;
  }
  .image img {
    width: 100%;
    height: auto;
    height: auto;
  }
  .content {
    flex: 2;
    padding: 0 20px; /* Padding around content */
  }
  .title {
    font-size: 28px; /* Larger font size */
    font-weight: bold;
    margin-bottom: 15px; /* Increase bottom margin */
    color: #333; /* Darker color */
  }
  .description {
    margin-bottom: 15px; /* Increase bottom margin */
    line-height: 1.6; /* Increase line height */
    color: #666; /* Slightly lighter color */
    font-size: 20px;
  }
  .details {
    font-size: 20px; /* Slightly larger font size */
    color: #777; /* Slightly darker color */
    display: flex;
    flex-direction: column;
    gap: 8px; /* Add space between details */
  }
  .img-wrapper {
    width: 100%;
    max-height: 400px;
  }
  .img-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at top left,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1) 70%
    );
    opacity: 0.4;
    pointer-events: none;
  }
`

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Set modal width to 80% of the viewport width
    height: "90%", // Set modal height to 80% of the viewport height
    overflow: "auto",
  },
}

const ProjectListing = ({ post, featured, height }) => {
  const [isImageTall, setIsImageTall] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const imageWrapperRef = useRef(null)

  const featuredImageData =
    post?.frontmatter?.featuredImage &&
    post?.frontmatter?.featuredImage.childImageSharp
      ? post?.frontmatter?.featuredImage.childImageSharp.fluid
      : null

  useEffect(() => {
    const imageWrapper = imageWrapperRef.current
    if (imageWrapper) {
      const img = imageWrapper.querySelector("img")
      if (img) {
        const checkImageDimensions = () => {
          setIsImageTall(img.naturalHeight > img.naturalWidth)
        }
        if (img.complete) {
          checkImageDimensions()
        } else {
          img.onload = checkImageDimensions
        }
      }
    }
  }, [featuredImageData])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <ProjectContainer featured={featured} height={height}>
      <div className={`container ${isImageTall ? "flex" : ""}`}>
        <div
          className={`image ${isImageTall ? "margin-right" : ""}`}
          ref={imageWrapperRef}
          onClick={openModal}
        >
          {featuredImageData && (
            <Img
              className="img-wrapper"
              fluid={featuredImageData}
              objectPosition={"0% 0%"}
            />
          )}
        </div>
        <div className="content">
          <div className="title">{post?.frontmatter?.title}</div>
          <div className="description">{post?.frontmatter?.summary}</div>
          <div className="details">
            {post?.frontmatter?.location && (
              <div>📍 {post?.frontmatter?.location}</div>
            )}
            {post?.frontmatter?.tags && (
              <div>Industries: {post?.frontmatter?.tags}</div>
            )}
            {post?.frontmatter?.cost && (
              <div>Approximate Price: {post?.frontmatter?.cost}</div>
            )}
            {post?.frontmatter?.link && (
              <div>
                Link:{" "}
                <a
                  href={post?.frontmatter?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {post?.frontmatter?.link}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Image Modal"
      >
        {featuredImageData && (
          <Img
            className="img-wrapper"
            fluid={featuredImageData}
            objectFit="contain"
            objectPosition={"0% 0%"}
            style={{ width: "100%", height: "100%" }}
          />
        )}
        <button onClick={closeModal}>Close</button>
      </Modal>
    </ProjectContainer>
  )
}

ProjectListing.propTypes = {
  post: PropTypes.object.isRequired,
  featured: PropTypes.bool.isRequired,
  height: PropTypes.string, // Add height prop type
}

ProjectListing.defaultProps = {
  featured: false,
  height: "650px", // Default height value
}

export default ProjectListing
