import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import Container from "components/Container"

const BlogListingWrapper = styled.div`
  width: 100%;
  cursor: pointer;

  .img-wrapper {
    width: 100%;
    max-height: 400px;
    border-radius: 7px;
  }

  .blog-listing-container {
    padding: 1.5rem 0 0;
    margin: 0 auto 1rem;
    max-width: inherit;
    border-radius: 7px;
    color: #162a3e;
    border: 2px solid #ffd17a;

    ${({ featured }) =>
      featured &&
      css`
        background: white;
      `}
  }

  .blog-listing-text {
    width: 100%;
    padding: 1rem 0 0;
    h1 {
      font-family: "League Spartan", sans-serif;
      font-size: 1.4rem;
    }
  }

  .blog-listing-text p,
  .blog-listing-text h1 {
    -webkit-transition: all 250ms ease-in;
    -moz-transition: all 250ms ease-in;
    -ms-transition: all 250ms ease-in;
    -o-transition: all 250ms ease-in;
    transition: all 250ms ease-in;
    ${({ featured }) => featured && " color: #162a3e;"}
  }

  :hover,
  :active {
    .img-wrapper {
      filter: grayscale(0) brightness(1.1);
    }
    -moz-transform: scale(1.015);
    -webkit-transform: scale(1.015);
    -o-transform: scale(1.015);
    -ms-transform: scale(1.015);
    transform: scale(1.015);
    transition: transform 100ms ease-in;
  }

  .blog-listing-container,
  .img-wrapper {
    -webkit-transition: filter 100ms ease-in;
    -moz-transition: filter 100ms ease-in;
    -ms-transition: filter 100ms ease-in;
    -o-transition: filter 100ms ease-in;
    transition: filter 100ms ease-in;
    transition-property: filter, background;
  }

  @media only screen and (min-width: 800px) {
    .blog-listing-container {
      flex-flow: row nowrap;
      height: 300px;
      padding: 0;

      justify-content: space-evenly;

      ${({ featured }) =>
        featured &&
        css`
          background: white;
        `}
    }

    .img-wrapper {
      height: 100%;
      width: 400px;
      border-radius: 0 10px 10px 0;
      filter: grayscale(0.25) brightness(0.9);
    }

    .blog-listing-text {
      width: 90%;
    }
  }
`

const BlogListing = ({ post, featured }) => {
  // Check if featuredImage and childImageSharp exist
  const featuredImageData =
    post.frontmatter.featuredImage &&
    post.frontmatter.featuredImage.childImageSharp
      ? post.frontmatter.featuredImage.childImageSharp.fluid
      : null

  return (
    <BlogListingWrapper featured={featured}>
      <Link to={post.frontmatter.path}>
        <Container className="blog-listing-container" noTitle secondary>
          <div className="blog-listing-text">
            <div
              style={{
                textAlign: "left",
                padding: "0",
                width: "100%",
                margin: "0 auto",
              }}
            >
              <h1
                style={{
                  textDecoration: "underline",
                  padding: "0 0 0.25rem",
                  margin: "0 1rem",
                }}
              >
                {post.frontmatter.title}
              </h1>

              <h3
                style={{
                  padding: "0 0 0.25rem",
                  margin: "1rem 1rem",
                }}
              >
                Service: {post.frontmatter.tags}
              </h3>

              <p
                style={{
                  padding: "0 0 0.25rem",
                  fontWeight: "normal",
                  margin: "0 1rem",
                }}
              >
                {post.frontmatter.date}
              </p>
            </div>

            <p
              style={{
                fontWeight: "normal",
                padding: "1rem 0",
                margin: "0 1rem",
              }}
            >
              {post.excerpt}
            </p>
          </div>
          {/* {featuredImageData && (
            <Image
              className="img-wrapper"
              fluid={featuredImageData}
              objectPosition={"50% 20%"}
            />
          )} */}
        </Container>
      </Link>
    </BlogListingWrapper>
  )
}

BlogListing.propTypes = {
  post: PropTypes.object.isRequired,
  featured: PropTypes.bool.isRequired,
}

BlogListing.defaultProps = {
  featured: false,
}

export default BlogListing
